
import axios from '@/axios'
import { DefaultTable } from 'apptimizm-ui'
import { DefaultTableExposed, TableHeader } from 'apptimizm-ui/src/ui/default-table/default-table'
import moment from 'moment'
import { Ref } from 'vue'
import { Journal, JournalMeta } from './types'

interface TableFilter {
  search: Ref<string>
  readonly tableParams: {
    [code: string]: string
  }
}

interface ITableProps {
  table: Ref<DefaultTableExposed>
  tableFilter: TableFilter
}

export default ({ table, tableFilter }: ITableProps) => {
  const journalMeta = new JournalMeta()

  const headers: TableHeader[] = [
    { name: '№ события', sort: 'fakeId', minWidth: '120px' },
    { name: 'Дата', sort: 'createdAt', minWidth: '140px' },
    { name: 'Время', sort: 'createdAt', minWidth: '100px' },
    { name: 'Тип события', sort: 'title', minWidth: '260px' },
    { name: 'Комментарий', sort: 'comment', minWidth: '650px' }
  ]

  const line = (item: Journal) => {
    return (
      <tr>
        <td>{item.fakeId}</td>
        <td>{moment(item.createdAt).locale('ru').format('LL')}</td>
        <td>{moment(item.createdAt).format('HH:mm')}</td>
        <td>{item.title.name}</td>
        <td style="max-width: 1px;">{item.comment}</td>
      </tr>
    )
  }

  return (
    <div class='table-wrapper'>
      <DefaultTable
        axios={axios}
        endpoint={journalMeta.endpoint}
        defaultFilter={tableFilter.tableParams}
        ref={table}
        line={line}
        itemConverter={(v: any) => journalMeta.load(v)}
        headers={headers}
        responseItemsKey="results"
        responseTotalKey="count"
        paginationType="page"
        scrollPagination={true}
        perPage={20}
      />
    </div>
  )
}
