import { host } from '@/const'
import { Entity, EntityMeta } from 'apptimizm-ui'

class Journal extends Entity {
  id: number = 0
  fakeId: number = 0
  createdAt: string = ''
  comment: string = ''
  title: IComment = { name: '', value: '' }
}

interface IComment {
  name: string
  value: string
}

class JournalMeta extends EntityMeta<Journal> {
  endpoint: string = host + '/api/v1/event/'

  fields = {
    id: { type: Number },
    fakeId: { type: Number, backendkey: 'fake_id' },
    createdAt: { type: String, backendkey: 'created_at' },
    comment: { type: String },
    title: { type: Object }
  }

  load (data: any): Journal {
    const result = super.load(data)
    result.createdAt = data.created_at
    result.fakeId = data.fake_id
    return result
  }
}

export { Journal, JournalMeta }
